<template>
<div>
<header v-if="!plain">
  <h1><router-link :to="{name: 'Dashboard'}">Efaktura statistike</router-link></h1>
  <button @click="logout">Logout</button>
</header>
<router-view :key="$route.fullPath"></router-view>
</div>
</template>

<script>
import { RouterView } from 'vue-router'
export default {
  components: {
    RouterView
  },
  data: ()=>({
    loggedIn: false,
  }),
  computed: {
    plain() {
      return !!this.$route?.meta?.plain;
    },
  },
  methods: {
    logout() {
      this.loggedIn=false;
      localStorage.removeItem("efToken");
      this.$router.push({name: "Login"});
    },
  },
  created() {
    window.vueApp=this;
  },
}
</script>

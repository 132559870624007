<template>
<div v-if="!loading" class="login-container">
<form @submit.prevent="doLogin">
<input type="text" v-model="username" required>
<input type="password" v-model="password" required>
<button>Login</button>
</form>
</div>
</template>

<script>
export default {
  data: ()=>({
    loading: true,
    username: '',
    password: '',
  }),
  methods: {
    async doLogin() {
      var result=await appApi.doLogin(this.username, {method: "password", data: this.password});
      if (result.token) {
        vueApp.loggedIn=true;
        localStorage.setItem("efToken", result.token);
        this.$router.push({name: "Dashboard"});
      } else {
        alert("Invalid username or password");
      }
    },
    async doAutoLogin() {
      if (localStorage.getItem("efToken", null)) {
        await new Promise(resolve=>setTimeout(resolve, 1000));
        vueApp.loggedIn=true;
        this.$router.push({name: "Dashboard"});
      }
      this.loading=false;
    },
  },
  created() {
    this.doAutoLogin();
  },
}
</script>

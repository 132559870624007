export default [
  {
    path: '/',
    component: () => import('./views/Dashboard.vue'),
    name: "Dashboard",
    meta: { auth: true },
  },
  {
    path: '/client/:id',
    component: () => import('./views/Client.vue'),
    name: "Client",
    meta: { auth: true },
  },
  {
    path: '/report/:id',
    component: () => import('./views/Report.vue'),
    name: "Report",
    meta: { auth: true },
  },
  {
    path: '/admin',
    component: () => import('./views/Admin.vue'),
    name: "Admin",
    meta: { auth: true },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { noAuth: true, plain: true },
    component: () => import('./views/Login.vue')
  },
  {
    path: '/404page',
    name: '404page',
    meta: { plain: true },
    component: () => import('./views/404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: { plain: true },
    component: () => import('./views/404.vue')
  }
]

<template>
<div v-if="!loading" class="content">
<h2>Client: {{ client?.name }}</h2>

<table class="data-table">
  <thead>
    <tr>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Invoices</th>
      <th>Total VAT</th>
      <th>Actions</th>
    </tr>
    <tr>
      <td colspan="5">
        <button @click.prevent="newItem">New</button>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in searches">
      <td v-if="editId!=item.id">
        {{ item.dateStart.substring(0, 10) }}
      </td>
      <td v-else>
        <input type="date" v-model="item.dateStart" />
      </td>
      <td v-if="editId!=item.id">
        {{ item.dateEnd.substring(0, 10) }}
      </td>
      <td v-else>
        <input type="date" v-model="item.dateEnd" />
      </td>
      <td>
        {{ item.documentIds?.length }}
      </td>
      <td>
        {{ item.totalVat }}
      </td>
      <td v-if="editId!=item.id">
        <button @click.prevent="openReports(item)">{{ item.id ? 'Detailed report' : 'Fetching data' }}</button>
      </td>
      <td v-else>
        <button @click.prevent="dismissUser">Dismiss</button>
        <button @click.prevent="saveItem">Search</button>
      </td>
    </tr>
  </tbody>
</table>

</div>
</template>

<script>
export default {
  data: ()=>({
    loading: true,
    appData: {},
    client: null,
    searches: [],
    editId: -1,
  }),
  methods: {
    async loadCurrent() {
      this.appData=await appApi.root();
      this.client=(await appApi.list("Client", [
        ["userUuid", "=", this.appData.user.uuid],
        ["uuid", "=", this.$route.params.id]
      ]))[0];
      if (!this.client)
        return this.$router.replace({name: "Dashboard"});
      this.searches=await appApi.list("ClientSearch", [["clientUuid", "=", this.$route.params.id]], null, ["-id"]);
      this.loading=false;
    },
    newItem() {
      var item=appApi.create("ClientSearch");
      item.id=null;
      item.uuid=null;
      item.clientUuid=this.$route.params.id;
      item.dateStart='';
      item.dateEnd='';
      this.searches.unshift(item);
      this.editId=null;
    },
    async saveItem() {
      var item=this.searches.find(i=>i.id==this.editId);
      this.editId=-1;
      if (item) {
        if (!item.id)
          await item.save("{dateStart,dateEnd,clientUuid}");
        else
          await item.save("{dateStart,dateEnd}");
      }
      this.loadCurrent();
    },
    async deleteItem() {
      if (!confirm("Are you sure?"))
        return;
      if (this.editId!==null) {
        var item=this.searches.find(i=>i.id==this.editId);
        if (item) {
          item.delete();
        }
      }
      this.editId=-1;
      this.loadCurrent();
    },
    dismissUser() {
      this.loadCurrent();
      this.editId=-1;
    },
    openReports(item) {
      this.$router.push({name: "Report", params: {id: item.uuid}});
    },
  },
  mounted() {
    this.loadCurrent();
  },
}
</script>

import jQuery from 'jquery';
window.$=window.jQuery=jQuery;

import { createApp } from 'vue'
import RootApp from './App.vue'
import Api from './Util/api'

window.appApi=Api;
window.appEnv={
  apiRoot: "/api/",
};

var VueApp=createApp(RootApp)

var VueComponents = {};
var VueStores = {};
var vueComponentList = [
  require.context(".", true, /.+\.vue$/),
];

for(let vueComponentListItem of vueComponentList)
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    VueComponents[actualKey]=actualComponent;
    VueApp.component(actualKey, actualComponent);
  }


// var dynamicElements=document.querySelectorAll("[vue-dyn]");
// for(var element of dynamicElements) {
//   var elementApp=createApp({});
//   elementApp.use(Pinia);
//   for(var component in VueComponents)
//     elementApp.component(component, VueComponents[component]);
//   elementApp.mixin({data: ()=>({
//     stores: VueStores,
//   })});
//   elementApp.mount(element);
// }


import routes from './routes'
import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory("/"),
  routes
})

router.beforeEach(async (to, from, next) => {
  var isLoggedIn=window.vueApp.loggedIn;

  if (to.matched.some(r => r.meta.noAuth) && isLoggedIn) {
    return next({ name: 'Dashboard' })
  }

  if (to.matched.some(r => r.meta.auth) && !isLoggedIn) {
    return next({ name: 'Login' })
  }
  return next()
})
VueApp.use(router);


VueApp.mount(document.querySelector('#app'))
// window.vueApp=VueApp._instance.ctx;

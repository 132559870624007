<template>
<div v-if="!loading" class="content">
<h2>Admin</h2>

<table class="data-table">
  <thead>
    <tr>
      <th>Username</th>
      <th>Role</th>
      <th>Password</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in users">
      <td v-if="editId!=item.id">
        {{ item.username }}
      </td>
      <td v-else>
        <input type="text" v-model="item.username" />
      </td>
      <td v-if="editId!=item.id">
        {{ item.group }}
      </td>
      <td v-else>
        <select v-model="item.group">
          <option>admin</option>
          <option>user</option>
        </select>
      </td>
      <td v-if="editId!=item.id">
        •••••••••
      </td>
      <td v-else>
        <input type="text" v-model="item.newPassword" />
      </td>
      <td v-if="editId!=item.id">
        <button @click.prevent="editId=item.id">Edit</button>
      </td>
      <td v-else>
        <button @click.prevent="dismissUser">Dismiss</button>
        <button @click.prevent="saveItem">Save</button>
        <button @click.prevent="deleteItem">Delete</button>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="4">
        <button @click.prevent="newUser">New</button>
      </td>
    </tr>
  </tfoot>
</table>
</div>
</template>

<script>
export default {
  data: ()=>({
    loading: true,
    appData: {},
    users: [],
    editId: -1,
  }),
  methods: {
    async loadCurrent() {
      this.appData=await appApi.root();
      this.users=await appApi.list("User");
      this.loading=false;
      if (this.appData.user?.group!='admin')
        this.$router.replace({name: "Dashboard"});
    },
    newUser() {
      var item=appApi.create("User");
      item.id=null;
      item.uuid=null;
      item.username=null;
      item.group='user';
      item.consent=1;
      item.tokens=[{type: 'password'}];
      this.users.push(item);
      this.editId=null;
    },
    async saveItem() {
      var item=this.users.find(i=>i.id==this.editId);
      this.editId=-1;
      if (item) {
        if (!item.id)
          await item.save("{username,group,consent,tokens}");
        else
          await item.save("{username,group}");
        if (item.newPassword) {
          await item.action("setPassword", {password: item.newPassword});
        }
      }
      this.loadCurrent();
    },
    async deleteItem() {
      if (!confirm("Are you sure?"))
        return;
      if (this.editId!==null) {
        var item=this.users.find(i=>i.id==this.editId);
        if (item) {
          item.delete();
        }
      }
      this.editId=-1;
      this.loadCurrent();
    },
    dismissUser() {
      this.loadCurrent();
      this.editId=-1;
    },
  },
  mounted() {
    this.loadCurrent();
  },
}
</script>

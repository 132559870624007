<template>
<div v-if="!loading" class="content">
<h2>Clients</h2>

<table class="data-table">
  <thead>
    <tr>
      <th>Name</th>
      <th>API Key</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in clients">
      <td v-if="editId!=item.id">
        {{ item.name }}
      </td>
      <td v-else>
        <input type="text" v-model="item.name" />
      </td>
      <td v-if="editId!=item.id">
        {{ item.key }}
      </td>
      <td v-else>
        <input type="text" v-model="item.key" />
      </td>
      <td v-if="editId!=item.id">
        <button @click.prevent="editId=item.id">Edit</button>
        <button @click.prevent="openReports(item)">Reports</button>
      </td>
      <td v-else>
        <button @click.prevent="dismissUser">Dismiss</button>
        <button @click.prevent="saveItem">Save</button>
        <button @click.prevent="deleteItem">Delete</button>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td colspan="4">
        <button @click.prevent="newItem">New</button>
      </td>
    </tr>
  </tfoot>
</table>
</div>
</template>

<script>
export default {
  data: ()=>({
    loading: true,
    appData: {},
    clients: [],
    editId: -1,
  }),
  methods: {
    async loadCurrent() {
      this.appData=await appApi.root();
      if (this.appData.user?.group=='admin')
        return this.$router.replace({name: "Admin"});
      this.clients=await appApi.list("Client", [["userUuid", "=", this.appData.user.uuid]]);
      this.loading=false;
    },
    newItem() {
      var item=appApi.create("Client");
      item.id=null;
      item.uuid=null;
      item.userUuid=this.appData.user.uuid;
      item.name='';
      item.key='';
      this.clients.push(item);
      this.editId=null;
    },
    async saveItem() {
      var item=this.clients.find(i=>i.id==this.editId);
      this.editId=-1;
      if (item) {
        if (!item.id)
          await item.save("{name,key,userUuid}");
        else
          await item.save("{name,key}");
      }
      this.loadCurrent();
    },
    async deleteItem() {
      if (!confirm("Are you sure?"))
        return;
      if (this.editId!==null) {
        var item=this.clients.find(i=>i.id==this.editId);
        if (item) {
          item.delete();
        }
      }
      this.editId=-1;
      this.loadCurrent();
    },
    dismissUser() {
      this.loadCurrent();
      this.editId=-1;
    },
    openReports(item) {
      this.$router.push({name: "Client", params: {id: item.uuid}});
    },
  },
  mounted() {
    this.loadCurrent();
  },
}
</script>

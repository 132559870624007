<template>
<div v-if="!loading" class="content">
<h2>Client: {{ report?.client?.name }}</h2>
<h2>Report: {{ report?.dateStart.substring(0, 10) }} - {{ report?.dateEnd.substring(0, 10) }}</h2>
<div class="actions">
  <button @click.prevent="downloadXlsx">Download XLSX</button>
</div>

<table class="data-table">
  <thead>
    <tr>
      <th>Broj fakture</th>
      <th>Datum Izdavanja</th>
      <th>Datum Prometa</th>
      <th>Kupac</th>
      <th>Oporeziv iznos</th>
      <th>Porez</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="item in invoices">
      <td>
        {{ item.documentNumber }}
      </td>
      <td>
        {{ item.date }}
      </td>
      <td>
        {{ item.date1 }}
      </td>
      <td>
        {{ item.customer }}
      </td>
      <td>
        {{ item.totalAmount }}
      </td>
      <td>
        {{ item.vatAmount }}
      </td>
    </tr>
  </tbody>
</table>

</div>
</template>

<script>
export default {
  data: ()=>({
    loading: true,
    appData: {},
    report: null,
    invoices: [],
  }),
  methods: {
    async loadCurrent() {
      this.appData=await appApi.root();
      this.report=(await appApi.list("ClientSearch", [
        ["client.userUuid", "=", this.appData.user.uuid],
        ["uuid", "=", this.$route.params.id]
      ]))[0];
      if (!this.report)
        return this.$router.replace({name: "Dashboard"});
      var invoiceIds=[];
      try {
      if (this.report.documentIds)
        invoiceIds=[...this.report.documentIds];
      } catch(e) { console.log(e); }
      this.invoices=await appApi.list("Efaktura", [
        ["userUuid", "=", this.appData.user.uuid],
        ["documentId", "IN", ...invoiceIds],
      ], null, ['id']);
      this.loading=false;
    },
    downloadXlsx() {
      location.href="/report-xlsx/"+this.$route.params.id+".xlsx";
    },
  },
  mounted() {
    this.loadCurrent();
  },
}
</script>
